import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { theme } from '../theme/theme';

const CustomBox = styled(Box, {
    shouldForwardProp: (prop) =>
        prop !== 'selected' && prop !== 'viewed' && prop !== 'hovered',
})(({ selected, viewed, hovered }) => ({
    ':hover': {
        backgroundColor: theme.palette.primary.hover,
    },
    backgroundColor: selected
        ? theme.palette.secondary.main
        : viewed
        ? theme.palette.common.grey[200]
        : theme.palette.common.white,
    border: `0.5px solid ${theme.palette.common.grey[400]}`,
}));

export default CustomBox;
