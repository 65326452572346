import React, { useState } from 'react';
import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { theme } from '../theme/theme';

const CustomButton = styled(Button)(() => ({
    marginTop: '1em',
    fontWeight: 'bold',
    width: '175px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.hover,
    },
    '&.Mui-disabled': {
        color: theme.palette.common.grey[600],
        backgroundColor: theme.palette.common.grey[300],
    },
}));

const LegalTracker = ({ setId }) => {
    const [inputErr, setInputErr] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(true);
    const [legalTrackerId, setLegalTrackerId] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        if (legalTrackerId) {
            setId({ legalTrackerId });
        }
    };

    const handlePaste = (event) => {
        let newValue = (event.clipboardData || window.clipboardData).getData(
            'text',
        );
        newValue = newValue.replace(/[^0-9]+/g, '');

        if (newValue.length === 9) {
            setLegalTrackerId(newValue);
            setInputErr(false);
            const currentYear = new Date().getFullYear();
            const idYear = Number(newValue.substring(0, 4));
            const validIdYear =
                idYear === currentYear || idYear === currentYear - 1;

            if (!newValue || isNaN(newValue) || !validIdYear) {
                setInputErr(true);
            } else {
                setInputErr(false);
                setContinueDisabled(false);
            }
        }

        event.preventDefault();
    };

    const handleKeyDown = (event) => {
        if (event.ctrlKey || event.metaKey) {
            return;
        }
        if (event.key === 'Backspace') {
            setInputErr(false);
            setContinueDisabled(true);
        } else if (
            !/[0-9]/.test(event.key) &&
            !['ArrowLeft', 'ArrowRight', 'Tab', 'Enter'].includes(event.key)
        ) {
            event.preventDefault();
        }
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        setLegalTrackerId(newValue);

        if (newValue.length > 0 && newValue.length < 9) {
            setInputErr(true);
        } else {
            setInputErr(false);
            const currentYear = new Date().getFullYear();
            const idYear = Number(newValue.substring(0, 4));
            const validIdYear =
                idYear === currentYear || idYear === currentYear - 1;

            if (!newValue || isNaN(newValue) || !validIdYear) {
                setInputErr(true);
            } else {
                setInputErr(false);
                setContinueDisabled(false);
            }
        }
    };

    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 236px)',
                width: '100vw',
            }}
        >
            <Grid
                container
                sx={{
                    border: `1px solid ${theme.palette.common.black}`,
                    borderRadius: '0.25rem',
                    padding: '1rem',
                    maxWidth: '750px',
                }}
            >
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: '2rem',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        Legal Inquiry
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <form
                        onSubmit={!continueDisabled ? handleSubmit : undefined}
                    >
                        <FormControl
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                padding: '1em',
                            }}
                        >
                            <TextField
                                id="formBasicText"
                                label={
                                    inputErr
                                        ? 'Please provide a valid ID:'
                                        : 'Tracker ID Number:'
                                }
                                inputProps={{ maxLength: 9 }}
                                error={inputErr}
                                value={legalTrackerId}
                                onKeyDown={handleKeyDown}
                                onChange={handleChange}
                                onPaste={handlePaste}
                            />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <CustomButton
                                    type="submit"
                                    disabled={continueDisabled}
                                >
                                    Continue
                                </CustomButton>
                            </Box>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
};

export default LegalTracker;
