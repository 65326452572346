import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from '../theme/theme';

const MediaLoading = () => {
    const containerRef = useRef(null);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            minHeight="calc(100vh / 1.78 - 48px)"
            bgcolor={theme.palette.common.black}
            ref={containerRef}
        >
            <CircularProgress style={{ color: 'white' }} />
        </Box>
    );
};

export default MediaLoading;
