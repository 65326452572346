import { styled } from '@mui/material/styles';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';

import { theme } from '../theme/theme';

const CustomIcon = styled(VideoFileOutlinedIcon, {
    shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'hovered',
})(({ selected, hovered }) => ({
    color:
        selected || hovered
            ? theme.palette.common.white
            : theme.palette.primary.main,
}));

export default CustomIcon;
