import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { theme } from '../theme/theme';

const AppMessage = ({ message }) => {
    return (
        <Grid
            container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
                textAlign: 'center',
                pointerEvents: 'none',
            }}
        >
            <Grid
                item
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '2em',
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {message}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default AppMessage;
