import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { theme } from '../theme/theme';

const MediaInfo = styled(Typography, {
    shouldForwardProp: (prop) =>
        prop !== 'selected' && prop !== 'hovered' && prop !== 'variant',
})(({ selected, hovered, variant }) => ({
    fontSize: variant === 'small' ? '0.75em' : 'inherit',
    color:
        selected || hovered
            ? theme.palette.common.white
            : variant === 'small'
            ? theme.palette.common.grey[700]
            : theme.palette.primary.main,
}));

export default MediaInfo;
