import Toast from 'react-bootstrap/Toast';
import CloseButton from 'react-bootstrap/CloseButton';

const ToastFail = (props) => {
    return (
        <Toast show={props.showFail} bg={'danger'} className={'legal-toast'}>
            <Toast.Body className={'text-white'}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                </svg>
                {`  Download Failed [${
                    props.downloadErrorMessage || 'Unknown Error'
                }] `}
                <a
                    href={props.supportLink}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={'text-white'}
                >
                    Contact SMART Support
                </a>
                <CloseButton
                    onClick={() => {
                        props.setShowFailToast(false);
                    }}
                    className={'float-end'}
                    variant="white"
                />
            </Toast.Body>
        </Toast>
    );
};

const ToastSuccess = (props) => {
    return (
        <Toast
            show={props.showSuccess}
            bg={'success'}
            className={'legal-toast'}
            autohide
            delay={3000}
            onClose={() => {
                props.setShowSuccessToast(false);
            }}
        >
            <Toast.Body className={'text-white'}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                >
                    <g fill="currentColor">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </g>
                </svg>
                {`  Download Successful`}
            </Toast.Body>
        </Toast>
    );
};

const LegalDownloadToast = (props) => {
    const toastFailProps = {
        showFail: props.showDownloadFailToast,
        setShowFailToast: props.setShowDownloadFailToast,
        downloadErrorMessage: props.downloadErrorMessage,
        supportLink: props.supportLink,
    };
    const toastSuccessProps = {
        showSuccess: props.showDownloadSuccessToast,
        setShowSuccessToast: props.setShowDownloadSuccessToast,
    };
    return (
        <>
            <ToastFail {...toastFailProps} />
            <ToastSuccess {...toastSuccessProps} />
        </>
    );
};

export default LegalDownloadToast;
