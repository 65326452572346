import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import API from '../lib/API';
import Socket from '../lib/Socket';
import Emitter from '../lib/Emitter';
import Logger from '../lib/Logger';

import MediaItem from './MediaItem';
import SelectedMedia from './SelectedMedia';
import NoMediaSelected from './NoMediaSelected';
import CustomCheckbox from './CustomCheckbox';
import ClipDownloadModal from './ClipDownloadModal.js';
import HeaderBar from './HeaderBar';
import SectionHeader from './SectionHeader';

import { theme } from '../theme/theme';

const Media = ({ id, isLegalUser }) => {
    const [mediaList, setMediaList] = useState([]);
    const [checkedMedia, setCheckedMedia] = useState({});
    const [loadedMedia, setLoadedMedia] = useState([]);
    const [viewedMedia, setViewedMedia] = useState([]);
    const [mediaItem, setMediaItem] = useState(null);
    const [showUI, setShowUI] = useState(false);
    const [allMediaSelected, setAllMediaSelected] = useState(false);
    const [isCheckboxHovered, setIsCheckboxHovered] = useState(false);
    const [supportLink, setSupportLink] = useState('');
    const [eventData, setEventData] = useState(null);

    const api = useMemo(() => new API(), []);
    const logger = useMemo(() => new Logger(), []);
    const socket = useMemo(() => new Socket(), []);

    const selectedObjects = useCallback(() => {
        return Object.entries(checkedMedia)
            .filter((media) => media[1])
            .map((media) => media[0]);
    }, [checkedMedia]);

    const handleToggleChecked = useCallback((uuid) => {
        setCheckedMedia((prevCheckedMedia) => ({
            ...prevCheckedMedia,
            [uuid]: !prevCheckedMedia[uuid],
        }));
    }, []);

    const handleMouseEnter = useCallback(() => {
        setIsCheckboxHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsCheckboxHovered(false);
    }, []);

    const handleCheckAllMedia = useCallback(() => {
        setCheckedMedia((prevCheckedMedia) => {
            const newCheckedMedia = { ...prevCheckedMedia };
            const anyUnchecked = mediaList.some(
                (mediaItem) => !newCheckedMedia[mediaItem.uuid],
            );

            mediaList.forEach((mediaItem) => {
                newCheckedMedia[mediaItem.uuid] = anyUnchecked;
            });

            return newCheckedMedia;
        });
        setAllMediaSelected((prevAllMediaSelected) => !prevAllMediaSelected);
    }, [mediaList]);

    const handleSelectMedia = useCallback(
        async (item) => {
            if (mediaItem && item.uuid === mediaItem.data.uuid) {
                return;
            }

            setMediaItem({ data: { uuid: item.uuid } });

            let updatedLoadedMedia = loadedMedia;
            let media = loadedMedia.find(
                (media) => media.data.uuid === item.uuid,
            );

            if (!media) {
                media = await api.getSignedUrl(item.uuid);
            }

            let updatedViewedMedia = [...viewedMedia, item.uuid];

            if (media?.data?.error) {
                logger.error(media.data.error);
            }

            if (!media || media.data?.error) {
                media = {
                    data: {
                        error: true,
                        url: '',
                    },
                };
            } else {
                media = {
                    error: false,
                    ...media,
                };

                updatedLoadedMedia = [...loadedMedia, media];
            }

            setMediaItem(media);
            setLoadedMedia(updatedLoadedMedia);
            setViewedMedia(updatedViewedMedia);
        },
        [loadedMedia, viewedMedia, mediaItem, api, logger],
    );

    const handleShowUI = useCallback((data) => {
        if (data.until) {
            let now = new Date();
            let until = new Date(data.until);
            let timeout = Math.min(until - now, 2147483647);
            setTimeout(() => {
                Emitter.emit('APP_ERROR', {
                    message:
                        "This alarm doesn't exist or you no longer have permission to view the alarm",
                    reason: 'Timeout event',
                });
            }, timeout);
        }

        if (data.token) {
            Emitter.emit('AUTH_TOKEN_RECEIVED', data);
        }
        setShowUI(true);

        Emitter.emit('APP_MESSAGE', {
            message: null,
        });
    }, []);

    const handleSetMediaList = useCallback((socketData) => {
        socketData.data.sort((first, second) => {
            let firstTimestamp = Date.parse(first.startTime);
            if (isNaN(firstTimestamp)) {
                firstTimestamp = Date.parse(first.created);
            }
            let secondTimestamp = Date.parse(second.startTime);
            if (isNaN(secondTimestamp)) {
                secondTimestamp = Date.parse(second.created);
            }
            return firstTimestamp > secondTimestamp ? 1 : -1;
        });

        setMediaList(socketData.data);
    }, []);

    const handleSetEventData = useCallback((socketData) => {
        setEventData(socketData.data);
        setSupportLink(socketData.supportLink || '');
    }, []);

    const handleResourceTimeout = useCallback(
        (socketData) => {
            if (id !== socketData.id) {
                return;
            }

            Emitter.emit('APP_ERROR', {
                message:
                    "This alarm doesn't exist or you no longer have permission to view the alarm",
                reason: 'Resource timeout',
            });
        },
        [id],
    );

    const receiveUpdate = useCallback(
        (socketData) => {
            switch (socketData.type) {
                case 'alarm-media':
                    handleSetMediaList(socketData);
                    break;
                case 'alarm-event':
                    handleSetEventData(socketData);
                    break;
                case 'resource-timeout':
                    handleResourceTimeout(socketData);
                    break;
                default:
                    break;
            }
        },
        [handleSetMediaList, handleSetEventData, handleResourceTimeout],
    );

    const joinSocket = useCallback(() => {
        socket.join('alarm', id, receiveUpdate);
    }, [id, receiveUpdate, socket]);

    const handleJoinError = useCallback((socketData) => {
        if (socketData.status >= 400 && socketData.status < 500) {
            socketData.message =
                "This alarm doesn't exist or you no longer have permission to view the alarm";
            Emitter.emit('APP_ERROR', socketData);
        }
        setShowUI(false);
    }, []);

    const getDisplayMedia = useCallback(() => {
        if (mediaItem && mediaList) {
            const mediaError = mediaItem.data.error;
            const mediaType = mediaItem.data.contentType;
            const videoElementId = mediaItem.data.uuid;
            const videoElementUrl = mediaItem.data.url;
            const props = {
                mediaError,
                mediaType,
                videoElementId,
                videoElementUrl,
            };

            return <SelectedMedia {...props} />;
        } else {
            return <NoMediaSelected />;
        }
    }, [mediaItem, mediaList]);

    useEffect(() => {
        Emitter.on('MEDIA_ITEMS_FETCHED', handleSetMediaList);
        Emitter.on('APP_ERROR', () => setShowUI(false));
        socket.socketInit();
        socket.getSocket().on('join-success', handleShowUI);
        socket.getSocket().on('join-error', handleJoinError);
        joinSocket();

        return () => {
            Emitter.off('MEDIA_ITEMS_FETCHED', handleSetMediaList);
            Emitter.off('APP_ERROR', () => setShowUI(false));
            socket.close();
        };
    }, [handleSetMediaList, joinSocket, handleShowUI, handleJoinError, socket]);

    useEffect(() => {
        const allSelected = mediaList.every(
            (mediaItem) => checkedMedia[mediaItem.uuid],
        );

        if (allSelected !== allMediaSelected) {
            setAllMediaSelected(allSelected);
        }
    }, [checkedMedia, mediaList, allMediaSelected]);

    if (!id || !showUI || !eventData) return null;

    return (
        <Grid container alignItems="stretch">
            <Grid item xs={12}>
                {eventData && <HeaderBar eventData={eventData} />}
            </Grid>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    lg={4}
                    sx={{
                        minWidth: '300px',
                        order: { xs: 2, lg: 1 },
                    }}
                >
                    <Paper>
                        <Grid container>
                            <Grid item xs={12}>
                                <SectionHeader text="Media" />
                            </Grid>
                            {mediaList.length > 0 && isLegalUser && (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        backgroundColor:
                                            theme.palette.common.grey[100],
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={0}
                                        alignItems="center"
                                    >
                                        <Grid item xs={1} lg={3}>
                                            <Grid container>
                                                <Grid item xs={6}></Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Tooltip
                                                        title={
                                                            allMediaSelected
                                                                ? 'All Media Clips Selected!'
                                                                : 'Select All Media Clips'
                                                        }
                                                        placement="right"
                                                    >
                                                        <span>
                                                            <CustomCheckbox
                                                                checked={
                                                                    allMediaSelected
                                                                }
                                                                onClick={
                                                                    handleCheckAllMedia
                                                                }
                                                                onMouseEnter={
                                                                    handleMouseEnter
                                                                }
                                                                onMouseLeave={
                                                                    handleMouseLeave
                                                                }
                                                                hovered={
                                                                    isCheckboxHovered
                                                                }
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9} lg={7}>
                                            <Typography
                                                sx={{
                                                    fontSize: '0.75em',
                                                    fontWeight: 'bold',
                                                    color: allMediaSelected
                                                        ? theme.palette.primary
                                                              .main
                                                        : theme.palette.common
                                                              .grey[700],
                                                }}
                                            >
                                                {allMediaSelected
                                                    ? 'All Media Clips Selected!'
                                                    : 'Select All Media Clips'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {mediaList.length > 0 &&
                                    mediaList.map((item) => (
                                        <MediaItem
                                            key={item.uuid}
                                            mediaItem={item}
                                            handleSelectMedia={
                                                handleSelectMedia
                                            }
                                            isSelectedItem={
                                                item.uuid ===
                                                mediaItem?.data?.uuid
                                            }
                                            isViewedItem={viewedMedia.includes(
                                                item.uuid,
                                            )}
                                            isLegalUser={isLegalUser}
                                            checked={!!checkedMedia[item.uuid]}
                                            handleToggleChecked={
                                                handleToggleChecked
                                            }
                                        />
                                    ))}
                            </Grid>
                            <Grid item xs={12}>
                                {mediaList.length > 0 && isLegalUser && (
                                    <ClipDownloadModal
                                        checkedMedia={selectedObjects()}
                                        supportLink={supportLink}
                                        incidentId={id}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={8}
                    sx={{
                        order: { xs: 1, lg: 2 },
                    }}
                >
                    <Paper>
                        <Grid item xs={12}>
                            <SectionHeader text="Player" />
                        </Grid>
                        <Grid item xs={12}>
                            {getDisplayMedia()}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Media;
