// see https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
const LogSeverity = {
    DEBUG: 100,
    INFO: 200,
    NOTICE: 300,
    WARNING: 400,
    ERROR: 500,
    CRITICAL: 600,
    ALERT: 700,
    EMERGENCY: 800,
};

class Logger {
    static preCallback = false;
    static postCallback = false;
    static source = '';
    static alarmId = '';
    static logRoom = 'ui-log-event';
    static userId;

    static setUserId(userId) {
        Logger.userId = userId;
    }
    static getUserId() {
        return Logger.userId ?? 'unknown';
    }

    static setPreCallback(callback) {
        Logger.preCallback = callback;
    }

    static setPostCallback(callback) {
        Logger.postCallback = callback;
    }

    static getPreCallback() {
        return Logger.preCallback;
    }

    static getPostCallback() {
        return Logger.postCallback;
    }

    static setSource(source) {
        Logger.source = source;
    }

    static getSource() {
        return Logger.source;
    }
    static setAlarmId(alarmId) {
        Logger.alarmId = alarmId;
    }

    constructor() {
        this.sensitiveData = {};
        this.logInfo = {
            source: Logger.source,
            alarmId: Logger.alarmId,
        };
    }

    /**
     * Debug or trace information
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    debug(message, data = null, options = {}) {
        this.write('DEBUG', message, data, options);
    }

    /**
     * Normal but significant events, such as start up, shut down, or a configuration change.
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    notice(message, data = null, options = {}) {
        this.write('NOTICE', message, data, options);
    }

    /**
     * Warning events might cause problems.
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    warning(message, data = null, options = {}) {
        this.write('WARNING', message, data, options);
    }

    /**
     * Error events are likely to cause problems.
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    error(message, data = null, options = {}) {
        this.write('ERROR', message, data, options);
    }

    /**
     * Critical events cause more severe problems or outages.
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    critical(message, data = null, options = {}) {
        this.write('CRITICAL', message, data, options);
    }

    /**
     * A person must take an action immediately.
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    alert(message, data = null, options = {}) {
        this.write('ALERT', message, data, options);
    }

    /**
     * One or more systems are unusable.
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    emergency(message, data = null, options = {}) {
        this.write('EMERGENCY', message, data, options);
    }

    /**
     * Alias for Logger.log
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    info(message, data = null, options = {}) {
        this.log(message, data, options);
    }

    /**
     * Routine information, such as ongoing status or performance.
     * @param {string} message
     * @param {Object} data
     * @param {(boolean|{audit: boolean, skipLocalLogging: boolean, skipPreCallback: Boolean, skipPostCallback: boolean})} options
     *     Options can accept a boolean rather than an object to handle backwards compatibility with the audit parameter.
     *     All object properties are assumed false if not specified.
     */
    log(message, data = null, options = {}) {
        this.write('INFO', message, data, options);
    }

    /**
     * You probably shouldn't be calling this method directly.
     */
    write(severity, message, data = null, options = {}) {
        // Convert legacy audit boolean parameter into object property
        if (typeof options === 'boolean') {
            options = { audit: options };
        }
        try {
            let preCallback = Logger.getPreCallback();
            let postCallback = Logger.getPostCallback();

            if (preCallback && !options.skipPreCallback) {
                message = preCallback(severity, message, data);
            }

            let currentlogInfo = JSON.parse(JSON.stringify(this.logInfo));
            currentlogInfo.userId = currentlogInfo.userId ?? Logger.getUserId();
            if (options.audit) {
                currentlogInfo.audit = true;
            }

            // see https://cloud.google.com/logging/docs/structured-logging#special-payload-fields
            currentlogInfo.severity = severity;

            currentlogInfo.message = message;
            if (data) {
                currentlogInfo.data = data;
            }

            let values = JSON.stringify(currentlogInfo, (key, value) => {
                // Filtering out nulled properties
                return value === null ? undefined : value;
            });

            let obj = currentlogInfo;

            //Replace sensitive data
            for (const sensitive in this.sensitiveData) {
                values = values.replace(
                    sensitive,
                    this.sensitiveData[sensitive],
                );
                obj[sensitive] = this.sensitiveData;
            }

            if (!options.skipLocalLogging) {
                if (LogSeverity[severity] >= LogSeverity['ERROR']) {
                    console.error(obj);
                } else if (LogSeverity[severity] === LogSeverity['WARNING']) {
                    console.warn(obj);
                } else {
                    console.log(obj);
                }
            }

            if (postCallback && !options.skipPostCallback) {
                postCallback(values);
            }
        } catch (error) {
            console.error(
                JSON.stringify({ message: 'log error', error: error.stack }),
            );
        }
    }

    //Special phone redaction mask method
    static redactPhone(logger, phone) {
        if (!phone) {
            return phone;
        }
        phone = phone.toString();
        if (phone.length !== 10) {
            logger.warning(
                'Redaction Error: Invalid phone length | ' + phone.length,
            );
            return logger.constructor.fullRedact(phone);
        }
        return logger.constructor.redact(logger, phone, 2, 3, '*', 5);
    }
    //Special signed url redaction mask method
    static redactSignedUrl(logger, signedUrl) {
        return logger.constructor.redact(logger, signedUrl, 110, 10, '.', 5);
    }
    //Special token redaction mask method
    static redactToken(logger, token) {
        return logger.constructor.redact(logger, token, 10, 10, '.', 5);
    }

    static fullRedact(str) {
        if (typeof str !== 'string') {
            str = str.toString();
        }
        return `redacted | length ${str.length}`;
    }

    static redact(logger, str, firstChar, lastChar, replace, replaceLength) {
        try {
            if (typeof str !== 'string') {
                logger.warning(
                    'First argument in logger.redact function must be a string',
                );
                return logger.constructor.fullRedact(str);
            }
            if (typeof firstChar !== 'number') {
                logger.warning(
                    'Second argument in logger.redact function must be a integer',
                );
                return logger.constructor.fullRedact(str);
            }
            if (typeof lastChar !== 'number') {
                logger.warning(
                    'Third argument in logger.redact function must be a integer',
                );
                return logger.constructor.fullRedact(str);
            }
            if (typeof replace !== 'string') {
                logger.warning(
                    'Fourth argument in logger.redact function must be a string',
                );
                return logger.constructor.fullRedact(str);
            }
            if (typeof replaceLength !== 'number') {
                logger.warning(
                    'Fith argument in logger.redact function must be a integer',
                );
                return logger.constructor.fullRedact(str);
            }
            if (firstChar + lastChar > str.length - 1) {
                logger.warning(
                    'Fist Char + Last Char logger.redact function can not be greater than redaction string - 1',
                );
                return logger.constructor.fullRedact(str);
            }
            return (
                str.substr(0, firstChar) +
                replace.repeat(replaceLength) +
                str.substr(str.length - lastChar, lastChar) +
                ' | length: ' +
                str.length
            );
        } catch (error) {
            logger.error('Logger redaction error', error);
            return logger.constructor.fullRedact(str);
        }
    }

    addSensitiveData(sensitive, maskFunction = undefined) {
        if (!sensitive) {
            return;
        }
        if (typeof sensitive !== 'string') {
            sensitive = sensitive.toString();
        }
        if (maskFunction && typeof maskFunction !== 'function') {
            maskFunction = undefined;
        }
        this.sensitiveData[sensitive] = maskFunction
            ? maskFunction(this, sensitive)
            : this.constructor.fullRedact(sensitive);
    }
}
export default Logger;
