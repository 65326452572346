import React, { useState } from 'react';
import { styled } from '@mui/system';
import axios from 'axios';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import LegalDownloadToast from './LegalDownloadToast';

import { theme } from '../theme/theme';

const CancelButton = styled(Button)(() => ({
    fontWeight: 'bold',
    width: '100px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.hover,
    },
}));

const DefaultButton = styled(Button)(() => ({
    fontWeight: 'bold',
    width: '100px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.hover,
    },
}));

const handleDownload = async (
    props,
    setDownloadFail,
    setDownloadSuccess,
    setDownloadErrorMessage,
) => {
    const objects = props.checkedMedia;
    const token = localStorage.getItem('authToken');
    try {
        const bulkRes = await axios.post(
            `${process.env.REACT_APP_SMART_API}/lockbox/media/v1/adt-res/bulk/download`,
            {
                eventId: props.incidentId,
                trackerFileNumber:
                    window.localStorage.getItem('legalTrackerId'),
                objects,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'smart-token': 'true',
                },
            },
        );
        const url = bulkRes.data?.url?.[0];
        if (!url) {
            setDownloadErrorMessage('No URL returned from server');
            setDownloadFail(true);
            return;
        }
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadSuccess(true);
    } catch (err) {
        const errorMessage =
            err?.response?.data?.error || err?.response?.statusText;
        if (errorMessage) {
            setDownloadErrorMessage(errorMessage);
        }
        setDownloadFail(true);
    }
};

const ClipDownloadModal = (props) => {
    const [open, setOpen] = useState(false);
    const [showDownloadFailToast, setShowDownloadFailToast] = useState(false);
    const [showDownloadSuccessToast, setShowDownloadSuccessToast] =
        useState(false);
    const [downloadErrorMessage, setDownloadErrorMessage] = useState('');

    const mediaChecked = Object.keys(props.checkedMedia).length > 0;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <React.StrictMode>
            <Box
                sx={{
                    paddingLeft: 0,
                    paddingRight: '0.5em',
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                    width: '100%',
                    textAlign: 'right',
                }}
            >
                <DefaultButton
                    variant="contained"
                    disabled={!mediaChecked}
                    onClick={() => {
                        handleOpen();
                        setShowDownloadFailToast(false);
                        setShowDownloadSuccessToast(false);
                    }}
                >
                    Download
                </DefaultButton>
            </Box>
            <Modal open={open} backdrop="static">
                <Fade in={open}>
                    <Paper
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '40%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '1.5em',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginBottom: '1em',
                            }}
                        >
                            Download Media Files
                        </Typography>
                        <Grid
                            container
                            spacing={2}
                            sx={{ textAlign: 'center' }}
                        >
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    You are requesting{' '}
                                    <strong>
                                        {
                                            Object.values(
                                                props.checkedMedia,
                                            ).filter((val) => val).length
                                        }{' '}
                                    </strong>{' '}
                                    media files.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    A <strong>zip</strong> file will be
                                    downloaded with all selected files.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    <strong>Tracker File Number:</strong>{' '}
                                    {window.localStorage.getItem(
                                        'legalTrackerId',
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    <strong>Incident ID:</strong>{' '}
                                    {props.incidentId}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                marginTop: '1em',
                            }}
                        >
                            <CancelButton
                                variant="contained"
                                onClick={handleClose}
                                sx={{ marginRight: '1em' }}
                            >
                                Cancel
                            </CancelButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => {
                                    handleClose();
                                    handleDownload(
                                        props,
                                        setShowDownloadFailToast,
                                        setShowDownloadSuccessToast,
                                        setDownloadErrorMessage,
                                    );
                                }}
                            >
                                Continue
                            </DefaultButton>
                        </Box>
                    </Paper>
                </Fade>
            </Modal>
            <LegalDownloadToast
                showDownloadFailToast={showDownloadFailToast}
                showDownloadSuccessToast={showDownloadSuccessToast}
                setShowDownloadFailToast={setShowDownloadFailToast}
                setShowDownloadSuccessToast={setShowDownloadSuccessToast}
                downloadErrorMessage={downloadErrorMessage}
                supportLink={props.supportLink}
            />
        </React.StrictMode>
    );
};

export default ClipDownloadModal;
