import React, { useState, useEffect } from 'react';
import Moment from 'moment';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';

import CustomBox from './CustomBox';
import CustomIcon from './CustomIcon';
import DetectionGrid from './DetectionGrid';
import MediaInfo from './MediaInfo';
import MediaListCheckbox from './MediaListCheckbox';

import Emitter from '../lib/Emitter';

import { theme } from '../theme/theme';

const MediaItem = ({
    mediaItem,
    handleSelectMedia,
    isSelectedItem,
    isViewedItem,
    isLegalUser,
    checked,
    handleToggleChecked,
    ...props
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [brokenLinks, setBrokenLinks] = useState([]);

    useEffect(() => {
        const handleBrokenLink = (e) => {
            const { uuid } = e;
            if (!brokenLinks.includes(uuid)) {
                setBrokenLinks([...brokenLinks, uuid]);
            }
        };

        Emitter.on('LINK_ERROR', handleBrokenLink);

        return () => {
            Emitter.off('LINK_ERROR', handleBrokenLink);
        };
    }, [brokenLinks]);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const checkboxClick = (e) => {
        e.stopPropagation();
        handleToggleChecked(mediaItem.uuid);
    };

    const checkboxChange = (e) => {
        e.stopPropagation();
    };

    Moment.locale('en');

    let earlyDetections = [];

    if (mediaItem.earlyDetections) {
        for (const idx in mediaItem.earlyDetections) {
            let iconText = '';
            let detection = mediaItem.earlyDetections[idx];
            switch (detection) {
                case 'person':
                    iconText = 'directions_walk';
                    break;
                case 'car':
                    iconText = 'directions_car';
                    break;
                default:
                    break;
            }

            const confidence =
                mediaItem.detectionSummary?.[detection]?.confidence;

            earlyDetections.push({ iconText, confidence });
        }
    }

    let detections = earlyDetections.map((detection) => (
        <div key={detection.iconText}>
            <span
                key={detection.iconText}
                className="material-symbols-outlined"
            >
                {detection.iconText}
            </span>
            {detection.confidence && (
                <div
                    className="detection-confidence"
                    style={{
                        color:
                            isSelectedItem || isHovered
                                ? theme.palette.common.white
                                : theme.palette.common.grey[700],
                    }}
                >{`${detection.confidence * 100}%`}</div>
            )}
        </div>
    ));

    return (
        <Tooltip title="Select Media Clip" placement="right">
            <span>
                <CustomBox
                    component={Grid}
                    container
                    onClick={() => handleSelectMedia(mediaItem)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    selected={isSelectedItem}
                    viewed={isViewedItem}
                    hovered={isHovered}
                >
                    {isLegalUser ? (
                        <Grid item xs={1} sm={1} md={1} lg={3} xl={3}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CustomIcon
                                        selected={isSelectedItem}
                                        hovered={isHovered}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <MediaListCheckbox
                                        checked={checked}
                                        onChange={checkboxChange}
                                        onClick={checkboxClick}
                                        selected={isSelectedItem}
                                        hovered={isHovered}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={1}
                            sm={1}
                            md={1}
                            lg={3}
                            xl={3}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {brokenLinks.includes(mediaItem.uuid) ? (
                                <ErrorIcon style={{ color: 'red' }} />
                            ) : (
                                <CustomIcon
                                    selected={isSelectedItem}
                                    hovered={isHovered}
                                />
                            )}
                        </Grid>
                    )}
                    <Grid item xs={9} sm={9} md={9} lg={7} xl={7}>
                        <Grid container>
                            <Grid item xs={12}>
                                <MediaInfo
                                    variant="small"
                                    selected={isSelectedItem}
                                    hovered={isHovered}
                                >
                                    {Moment(mediaItem.startTime).format('L')}{' '}
                                    {Moment(mediaItem.startTime).format('LTS')}
                                </MediaInfo>
                            </Grid>
                            <Grid item xs={12}>
                                <MediaInfo
                                    selected={isSelectedItem}
                                    hovered={isHovered}
                                >
                                    {mediaItem.deviceName}
                                </MediaInfo>
                                {brokenLinks.includes(mediaItem.uuid) && (
                                    <Typography
                                        sx={{
                                            color:
                                                isSelectedItem || isHovered
                                                    ? theme.palette.common.white
                                                    : theme.palette.error.main,
                                        }}
                                    >
                                        Link Unavailable
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <DetectionGrid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        selected={isSelectedItem}
                        hovered={isHovered}
                    >
                        {detections}
                    </DetectionGrid>
                </CustomBox>
            </span>
        </Tooltip>
    );
};

export default MediaItem;
