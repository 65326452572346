import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { theme } from '../theme/theme';

const CustomCheckbox = styled(Checkbox, {
    shouldForwardProp: (prop) => prop !== 'hovered',
})(({ hovered }) => ({
    color: hovered
        ? theme.palette.common.grey[700]
        : theme.palette.common.grey[900],
    '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            fill: hovered
                ? theme.palette.primary.hover
                : theme.palette.primary.main,
        },
        '&.MuiIconButton-root': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export default CustomCheckbox;
