import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import { theme } from '../theme/theme';

const DetectionGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'hovered',
})(({ selected, hovered }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:
        selected || hovered
            ? theme.palette.common.white
            : theme.palette.primary.main,
}));

export default DetectionGrid;
