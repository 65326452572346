import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

import Emitter from '../lib/Emitter';

import { theme } from '../theme/theme';

const NoMediaSelected = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        Emitter.emit('APP_MESSAGE', '');
    }, []);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            minHeight="calc(100vh / 1.78 - 48px)"
            bgcolor={theme.palette.common.black}
            ref={containerRef}
        >
            <SvgIcon
                sx={{
                    fontSize: '3em',
                }}
            >
                <PlayCircleOutlineOutlinedIcon htmlColor="white" />
            </SvgIcon>
        </Box>
    );
};

export default NoMediaSelected;
