import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

import { theme } from '../theme/theme';

const MediaListCheckbox = styled(Checkbox, {
    shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'hovered',
})(({ selected, viewed, hovered }) => ({
    color:
        selected || hovered
            ? theme.palette.common.white
            : theme.palette.primary.main,
    '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            fill:
                selected || hovered
                    ? theme.palette.common.white
                    : theme.palette.primary.main,
        },
        '&.MuiIconButton-root': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export default MediaListCheckbox;
