import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './theme/index.css';
import App from './App';
import AppMessage from './components/AppMessage';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/alarm/:id">
                <App />
            </Route>
            <Route path="/">
                <React.StrictMode>
                    <AppMessage message={'No Alarm ID Selected'} />
                </React.StrictMode>
            </Route>
        </Switch>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
