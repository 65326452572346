import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { theme } from '../theme/theme';
import MediaLoading from './MediaLoading';

const SelectedMedia = (props) => {
    const { mediaError, mediaType, videoElementId, videoElementUrl } = props;
    const [aspectRatio, setAspectRatio] = useState(16 / 9);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setAspectRatio(16 / 9);

        const videoElement = document.getElementById(videoElementId);

        const calculateAspectRatio = () => {
            const { videoWidth, videoHeight } = videoElement;
            if (videoWidth > 0 && videoHeight > 0) {
                setAspectRatio(videoWidth / videoHeight);
            }
        };

        const handleLoadedMetadata = () => {
            calculateAspectRatio();
            setLoading(false);
        };

        if (videoElement) {
            videoElement.addEventListener(
                'loadedmetadata',
                handleLoadedMetadata,
            );

            return () => {
                videoElement.removeEventListener(
                    'loadedmetadata',
                    handleLoadedMetadata,
                );
            };
        }
    }, [videoElementId, videoElementUrl]);

    return (
        <Container
            style={{
                padding: 0,
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.common.black,
                zIndex: 9999,
            }}
        >
            {mediaError ? (
                <div
                    style={{
                        fontSize: 16,
                        display: 'inline',
                        width: '100%',
                        textAlign: 'center',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    Error loading clip
                </div>
            ) : (
                <div
                    style={{
                        padding: 0,
                        margin: 0,
                        display: 'flex',
                        zIndex: 1,
                        flexDirection: 'column',
                        maxHeight: '735px',
                        bgcolor: theme.palette.common.black,
                        width: '100%',
                        height: `calc(100vh / ${aspectRatio} - 128px)`,
                    }}
                >
                    {loading && <MediaLoading />}
                    <video
                        key={videoElementUrl}
                        id={videoElementId}
                        style={{
                            display: loading ? 'none' : 'inline',
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            padding: 0,
                        }}
                        controls
                        autoPlay
                    >
                        <source src={videoElementUrl} type={mediaType} />
                    </video>
                </div>
            )}
        </Container>
    );
};

export default SelectedMedia;
