import jwt from 'jwt-decode';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import Auth from './lib/Auth';
import Emitter from './lib/Emitter';
import Logger from './lib/Logger';

import Media from './components/Media';
import AppMessage from './components/AppMessage';
import LoginLogoutBar from './components/LoginLogoutBar';
import LegalInquiry from './components/Legal';

import './theme/App.css';

const App = () => {
    const [user, setUser] = useState(null);
    const [appMessage, setAppMessage] = useState(null);
    const [isLegal, setIsLegal] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [legalTrackerId, setLegalTrackerId] = useState(null);

    const { id } = useParams();
    const auth = useMemo(() => new Auth(), []);
    const logger = useMemo(() => new Logger(), []);

    const handleUserLogout = useCallback(() => {
        setUser(null);
        setIsLegal(false);
        setIsSupervisor(false);
        setLegalTrackerId(null);
        setAppMessage('You have been logged out');
    }, []);

    const handleLogin = useCallback(() => {
        setAppMessage('Logging in...');
        auth.popupLogin();
    }, [auth]);

    const handleLogout = useCallback(() => {
        auth.logout();
        handleUserLogout();
    }, [auth, handleUserLogout]);

    const handleLoginFinish = useCallback(() => {
        let authToken = window.localStorage.getItem('authToken');

        if (authToken) {
            try {
                const userData = jwt(authToken);
                setUser(userData);
                setIsLegal(userData?.userRoles?.includes('legal'));
                setIsSupervisor(userData?.userRoles?.includes('supervisor'));

                if (logger) {
                    logger.userId = userData.email;
                }
            } catch (error) {
                setAppMessage(`Failed to decode JWT token:\n${error}`);
            }
        } else {
            setAppMessage('Please login to continue');
        }
    }, [logger]);

    const handleAppMessage = useCallback(
        (socketData) => {
            if (socketData) {
                const { message, log } = socketData;
                if (log) {
                    logger.error(message, socketData);
                }
                setAppMessage(message);
            }
        },
        [logger],
    );

    const handleAuthTokenReceived = useCallback((socketData) => {
        window.localStorage.setItem('authToken', socketData.token);
    }, []);

    const handleSetLegalTrackerId = useCallback((payload) => {
        window.localStorage.setItem('legalTrackerId', payload.legalTrackerId);
        setLegalTrackerId(payload.legalTrackerId);
    }, []);

    useEffect(() => {
        if (id) {
            Logger.setSource('smart-portal-ui');
            Logger.setAlarmId(id);
        }

        handleLoginFinish();
    }, [id, handleLoginFinish]);

    useEffect(() => {
        Emitter.on('FIREBASE_LOGIN_FINISH', handleLoginFinish);
        Emitter.on('USER_LOGOUT', handleUserLogout);
        Emitter.on('APP_ERROR', handleAppMessage);
        Emitter.on('APP_MESSAGE', handleAppMessage);
        Emitter.on('AUTH_TOKEN_RECEIVED', handleAuthTokenReceived);

        return () => {
            Emitter.off('FIREBASE_LOGIN_FINISH', handleLoginFinish);
            Emitter.off('USER_LOGOUT', handleUserLogout);
            Emitter.off('APP_ERROR', handleAppMessage);
            Emitter.off('APP_MESSAGE', handleAppMessage);
            Emitter.off('AUTH_TOKEN_RECEIVED', handleAuthTokenReceived);
        };
    }, [
        handleAuthTokenReceived,
        handleLoginFinish,
        handleUserLogout,
        handleAppMessage,
    ]);

    let userButtonAction = user ? handleLogout : handleLogin;

    return (
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <LoginLogoutBar
                        userButtonAction={userButtonAction}
                        buttonText={user ? user.name : 'Log In'}
                        isLoggedIn={!!user}
                        isSupervisor={isSupervisor}
                    />
                </Grid>
            </Grid>
            <Grid container>
                {isLegal && !legalTrackerId ? (
                    <LegalInquiry setId={handleSetLegalTrackerId} />
                ) : (
                    <Grid item xs={12}>
                        {appMessage && <AppMessage message={appMessage} />}
                        {user && <Media id={id} isLegalUser={isLegal} />}
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default App;
