import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { theme } from '../theme/theme';

const LoginLogoutBar = (props) => {
    const { userButtonAction, buttonText, isLoggedIn, isSupervisor } = props;

    const supportIcon = <OpenInNewIcon />;
    const logIcon = isLoggedIn ? <LogoutIcon /> : <LoginIcon />;

    const toolbarStyle = {
        justifyContent: 'space-between',
        paddingLeft: '0',
        paddingRight: '0',
        paddingBottom: '1rem',
    };

    const handleSupportClick = () => {
        window.open(
            'https://iviewnow.atlassian.net/servicedesk/customer/portal/4',
        );
    };

    const formatButtonText = (text) => {
        if (!text) return '';
        return text
            .split(' ')
            .map(
                (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
            >
                <Toolbar style={toolbarStyle}>
                    {isSupervisor && (
                        <Tooltip title={'Submit Support Ticket'}>
                            <Button
                                sx={{
                                    minWidth: '118px',
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    '&:hover': {
                                        color: theme.palette.primary.hover,
                                        background: 'transparent',
                                    },
                                }}
                                onClick={handleSupportClick}
                                endIcon={supportIcon}
                            >
                                {formatButtonText('Contact Support')}
                            </Button>
                        </Tooltip>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    <Tooltip title={isLoggedIn ? 'Sign Out' : 'Sign In'}>
                        <Button
                            sx={{
                                minWidth: '118px',
                                color: theme.palette.primary.main,
                                fontWeight: 'bold',
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    color: theme.palette.primary.hover,
                                    background: 'transparent',
                                },
                            }}
                            onClick={userButtonAction}
                            endIcon={logIcon}
                        >
                            {formatButtonText(buttonText)}
                        </Button>
                    </Tooltip>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default LoginLogoutBar;
