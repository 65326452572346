import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import Logger from './Logger';
import Emitter from './Emitter';
import Auth from './Auth';

export default class Socket {
    constructor() {
        this.jwt = localStorage.getItem('authToken');
        this.socket = io(process.env.REACT_APP_API_URL, {
            auth: { token: 'Bearer ' + this.jwt, correlationId: uuidv4() },
            reconnectionDelay: 10000,
            transports: ['websocket'],
        });

        this.logger = new Logger();

        Logger.setPostCallback((data) => {
            if (this.socket) {
                this.socket.emit(Logger.logRoom, data);
            }
        });

        this.rooms = [];
        this.auth = new Auth();
    }

    socketInit() {
        let socket = this.socket;

        try {
            this.socket.on('error', (error) => {
                this.logger.error('socket error', error);
                Emitter.emit('APP_ERROR', {
                    message: 'Socket failure',
                    log: false,
                });
                socket.close();
            });
            this.socket.on('join-error', (data) => {
                this.logger.error(
                    'Unable to join room ' +
                        data.resource +
                        '/' +
                        data.identifier,
                    data,
                );
            });
            this.socket.on('join-success', (data) => {
                this.logger.log(
                    'successfully joined room ' +
                        data.resource +
                        '/' +
                        data.identifier +
                        '  until: ' +
                        data.until,
                    null,
                    { skipLocalLogging: true },
                );
                if (data.token) {
                    this.logger.addSensitiveData(
                        data.token,
                        Logger.redactToken,
                    );
                }
                this.logger.log('join data', data, { skipLocalLogging: true });
            });
            this.socket.on('connect_error', (error) => {
                this.logger.error('Socket connection error', error);
                if (error.message.toString() === 'authentication_error') {
                    Emitter.emit('APP_ERROR', {
                        message: 'Socket Unauthorized - Closing Connection',
                        log: false,
                    });
                    socket.disconnect();
                    this.auth.logout();
                }
            });
            this.socket.on('disconnect', (error) => {
                this.logger.log('disconnect ' + JSON.stringify(error), null, {
                    skipLocalLogging: true,
                });
            });
            this.socket.on('connect', () => {
                this.logger.log('Socket Connect', null, {
                    skipLocalLogging: true,
                });
            });
            this.socket.on('connected', () => {
                this.logger.log('Socket Connected', null, {
                    skipLocalLogging: true,
                });
                this.rooms.forEach((data) => {
                    this.logger.log(
                        'Joining room: ' +
                            data.resource +
                            '/' +
                            data.identifier,
                        null,
                        { skipLocalLogging: true },
                    );
                    this.logger.log('Data: ', data, { skipLocalLogging: true });
                    socket.emit('join', data);
                });
            });
            this.socket.on('authenticated', () => {});
        } catch (e) {
            this.logger.error('Socket initialization error', e);
        }
    }

    close() {
        this.socket.close();
    }

    getSocket() {
        return this.socket;
    }

    join(resource, identifier, callback) {
        let data = {
            resource: resource,
            identifier: identifier,
            callback: callback,
            correlationId: uuidv4(),
        };

        if (
            !this.rooms.find((r) => {
                return r.resource === resource && r.identifier === identifier;
            })
        ) {
            this.socket.on(
                data.resource + '/' + data.identifier,
                data.callback,
            );
            this.rooms.push(data);
        }
        if (this.socket.connected) {
            this.socket.emit('join', data);
        }
    }

    leave(resource, identifier) {
        let data = {
            resource: resource,
            identifier: identifier,
            correlationId: uuidv4(),
        };
        this.socket.off(data.resource + '/' + data.identifier);
        this.rooms = this.rooms.filter((r) => {
            return r.resource !== resource || r.identifier !== identifier;
        });
        if (
            !this.rooms.find((r) => {
                return r.resource === resource && r.identifier === identifier;
            })
        ) {
            this.rooms.push(data);
        }
        if (this.socket.connected) {
            this.socket.emit('leave', data);
        }
    }
}
