import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { theme } from '../theme/theme';

const SectionHeader = ({ text, actionArea }) => {
    return (
        <Container
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.primary.main,
                height: '2em',
                position: 'relative',
            }}
        >
            <Typography
                sx={{
                    color: theme.palette.common.white,
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                }}
            >
                {text}
            </Typography>
            {actionArea && (
                <div style={{ position: 'absolute', right: '0' }}>
                    {actionArea}
                </div>
            )}
        </Container>
    );
};

export default SectionHeader;
